import React, { useState, useEffect } from 'react';
import './StudentPrePost.css'; // Make sure to import the CSS file

const questions = [
    { id: 1, questionText: "Q1: Which specific type of bacteria is primarily responsible for the sour taste in sourdough bread?", options: ['a) Bacillus cereus', 'b) Lactobacillus sanfranciscensis', 'c) Escherichia coli', 'd) Staphylococcus aureus'], correctAnswer: 'b) Lactobacillus sanfranciscensis'},
    { id: 2, questionText: "Q2: What is the main byproduct of fermentation by wild yeast in sourdough?", options: ['a) Ethanol', 'b) Lactic acid','c) Acetic acid','d) Carbon dioxide'], correctAnswer: 'd) Carbon dioxide' },
    //{ id: 3, questionText: "Q3: Given that sourdough fermentation involves both yeast and lactic acid bacteria, how does this dual fermentation process improve the digestibility of the bread compared to bread made with commercial yeast alone?", options: ['The dual fermentation process reduces the gluten content.', 'Lactic acid bacteria break down complex carbohydrates and phytates, making nutrients more accessible.', 'The wild yeast creates larger air pockets, making the bread easier to chew.', 'The dual fermentation process increases the sugar content, making it more digestible.'], correctAnswer: 'Lactic acid bacteria break down complex carbohydrates and phytates, making nutrients more accessible.'},
    { id: 3, questionText: "Q3: Which of the following methods can help speed up the sourdough bread-making process without affecting its digestibility?", options: ['a) Decrease water, decrease the amount of starter in the dough, decrease pH, and decrease temperature.', 'b) Decrease water, increase the amount of starter in the dough, decrease pH, and increase temperature.', 'c) Increase water, increase the amount of starter in the dough, decrease pH, and decrease temperature.', 'd) Increase water, decrease the amount of starter in the dough, increase pH, and increase temperature.'], correctAnswer: 'b) Decrease water, increase the amount of starter in the dough, decrease pH, and increase temperature.'},
    // Add more questions as needed
];

const StudentPrePost = () => {
    const [email, setEmail] = useState(localStorage.getItem('email') || '');
    const [currentPhase, setCurrentPhase] = useState(localStorage.getItem('email') ? (localStorage.getItem('postAnswers') ? 'RESULTS' : (localStorage.getItem('preAnswers') ? 'WAIT' : 'PRE')) : 'EMAIL');
    const [preAnswers, setPreAnswers] = useState(JSON.parse(localStorage.getItem('preAnswers') || '{}'));
    const [postAnswers, setPostAnswers] = useState(JSON.parse(localStorage.getItem('postAnswers') || '{}'));
    const [percentages, setPercentages] = useState({});

    useEffect(() => {
        let intervalId;
        if (currentPhase === 'RESULTS') {
            fetchPercentages();
            intervalId = setInterval(fetchPercentages, 5000); // Fetch every 5 seconds
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId); // Clear interval on component unmount or phase change
            }
        };
    }, [currentPhase]); // Effect runs only when currentPhase changes

    const handleEmailSubmit = async () => { // Make sure to declare the function as async
        localStorage.setItem('email', email);
        setCurrentPhase('PRE');

        try {
            // Add the "fromdemo:" prefix to the email before sending it to the server
            const response = await fetch(`https://prepostclass.com/server-side/connector.php?email=fromdemo:${email}`);
            const result = await response.text();
        } catch (error) {
            console.log('error')
        }
    };


    const handleAnswer = (questionId, answer, isPost = false) => {
        const answers = isPost ? postAnswers : preAnswers;
        const updatedAnswers = {...answers, [questionId]: answer};
        if (isPost) {
            setPostAnswers(updatedAnswers);
            localStorage.setItem('postAnswers', JSON.stringify(updatedAnswers));
        } else {
            setPreAnswers(updatedAnswers);
            localStorage.setItem('preAnswers', JSON.stringify(updatedAnswers));
        }
    };

    const fetchPercentages = () => {
    fetch(`https://prepostclass.com/server-side/connector.php?action=getPercentages&timestamp=${new Date().getTime()}`)
        .then(response => response.json())
        .then(data => setPercentages(data))
        .catch(error => console.error('Error fetching percentages:', error));
    };

    const submitAnswers = () => {
        if (currentPhase === 'PRE') {
            setCurrentPhase('WAIT');
        } else if (currentPhase === 'POST') {
            setCurrentPhase('RESULTS');

            // Calculate the POST results
            const postResults = questions.map(question => {
                return postAnswers[question.id] === question.correctAnswer ? 1 : 2;
            });

            // Calculate the PRE results
            const preResults = questions.map(question => {
                return preAnswers[question.id] === question.correctAnswer ? 1 : 2;
            });

            // Prepare the URL with both PRE and POST results
            let url = `https://prepostclass.com/server-side/connector.php?`;
            postResults.forEach((result, index) => {
                url += `post-q${index + 1}=${result}&`;
            });
            preResults.forEach((result, index) => {
                url += `pre-q${index + 1}=${result}&`;
            });

            url = url.slice(0, -1); // Remove the last ampersand

            // Send the GET request
            fetch(url)
                .then(response => response.text())
                .then(data => console.log(data))
                .catch(error => console.error('Error:', error));

            fetchPercentages();  // Call to fetch percentages after posting answers

        }
    };


    const startPostTest = () => {
        setCurrentPhase('POST');
    };

    const resetQuiz = () => {
        localStorage.clear();
        setEmail('');
        setPreAnswers({});
        setPostAnswers({});
        setCurrentPhase('EMAIL');
    };

    const getAnswerClass = (questionId, option, answerKey) => {
        if (currentPhase !== 'RESULTS') {
            return ''; // Do not apply any class if it is not the RESULTS phase
        }

        const answers = answerKey === 'postAnswers' ? postAnswers : preAnswers;
        if (answers[questionId] === option) {
            return questions.find(q => q.id === questionId).correctAnswer === option ? 'correct-answer' : 'wrong-answer';
        }
        return '';
    };

    const getAnswerStatus = (questionId, answerKey) => {
        const answers = answerKey === 'postAnswers' ? postAnswers : preAnswers;
        if (questions.find(q => q.id === questionId).correctAnswer === answers[questionId]) {
            return '✔️'; // Check mark for correct answer
        } else {
            return '❌'; // Cross for incorrect answer
        }
    };

    const getPhaseMessage = () => {
        switch (currentPhase) {
            case 'EMAIL':
                return 'Start Pre-Lecture Test';
            case 'PRE':
                return 'Pre-Lecture Test';
            case 'WAIT':
                return 'Thank You!';
            case 'POST':
                return 'Post-Lecture Test';
            case 'RESULTS':
                return 'Check Your Answers';
            default:
                return '';
        }
    };

    const renderQuiz = (answers, isPost = false) => (
        <div className="quiz-container">
            {questions.map(question => (
                <div key={question.id} className="question">
                    <p><b>{question.questionText}</b></p>
                    {question.options.map(option => (
                        <div key={option} className={`option-label ${getAnswerClass(question.id, option, isPost ? 'postAnswers' : 'preAnswers')}`}>
                            <input
                                type="radio"
                                value={option}
                                name={question.id}
                                checked={answers[question.id] === option}
                                onChange={(e) => handleAnswer(question.id, e.target.value, isPost)}
                            />
                            {option}
                        </div>
                    ))}
                </div>
            ))}
            <button className="button" onClick={submitAnswers}>Submit Answers</button>
        </div>
    );

    const renderWaitPhase = () => (
        <div className="wait-container">
            <h1>Thank You!</h1>
            <p>Please don't close the page.</p>
            <p>🛑</p>
            <p>Now, listen closely to the instructor.</p>
            <p><span className="highlight">wait</span> until the end of the lecture to take the post-lecture test.</p>
            <button className="button" onClick={startPostTest}>Start post-lecture test</button>
        </div>
    );

    const renderResults = () => (
        <div>
            <h3>Results:</h3>
            {questions.map(question => (
                <div key={question.id}>
                    <p><b>{question.questionText}</b></p>
                    <p className="correct-answer">
                        Correct Answer: {question.correctAnswer}
                    </p>
                    <p className={getAnswerClass(question.id, preAnswers[question.id], 'preAnswers')}>
                        PRE Answer: {getAnswerStatus(question.id, 'preAnswers')} {preAnswers[question.id]}
                        <span className="percentage-display"> ({percentages[`pre-q${question.id}`] !== undefined ? `${percentages[`pre-q${question.id}`]}%` : 'Loading...'} of class answered PRE correctly)</span>
                    </p>
                    <p className={getAnswerClass(question.id, postAnswers[question.id], 'postAnswers')}>
                        POST Answer: {getAnswerStatus(question.id, 'postAnswers')} {postAnswers[question.id]}
                        <span className="percentage-display"> ({percentages[`post-q${question.id}`] !== undefined ? `${percentages[`post-q${question.id}`]}%` : 'Loading...'} of class answered POST correctly)</span>
                    </p>
                </div>
            ))}
            <button className="button" onClick={resetQuiz}>Reset Quiz</button>
        </div>
    );

    return (
        <div className="container">
            <div className="phase-indicator">{getPhaseMessage()}</div>
            {currentPhase === 'EMAIL' && (
                <div>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        className="input-field"
                    />
                    <button className="button" onClick={handleEmailSubmit}>Start Quiz</button>
                </div>
            )}
            {currentPhase === 'PRE' && renderQuiz(preAnswers)}
            {currentPhase === 'WAIT' && renderWaitPhase()}
            {currentPhase === 'POST' && renderQuiz(postAnswers, true)}
            {currentPhase === 'RESULTS' && renderResults()}
        </div>
    );

};

export default StudentPrePost;
