import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StudentPrePost from './StudentPrePost'; // Adjust the path as necessary
import { Link } from 'react-router-dom';

const App = () => {
    return (
        <Router>
            <div>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/demo" element={<StudentPrePost />} />
                    {/* Add more routes as needed */}
                </Routes>
            </div>
        </Router>
    );
};

const Home = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(''); // 'success' or 'error'
    const [loading, setLoading] = useState(false); // Loading state

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when request starts
        try {
            const response = await fetch(`https://prepostclass.com/server-side/connector.php?email=${email}`);
            const result = await response.text();
            setMessage(result);
            setMessageType('success');
        } catch (error) {
            setMessage('An error occurred. Please try again.');
            setMessageType('error');
        } finally {
            setLoading(false); // Set loading to false when request completes
        }
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>Pre Post Class</h1>
            <p>Get instant feedback on your students' performance!</p>
            <p>
                Test your students before and after the lecture to measure their learning in real-time.
            </p>
            <p>
                You make the quiz, and we grade and share the results!
            </p>
            <form onSubmit={handleSubmit} style={{ display: 'inline-block', marginTop: '20px' }}>
                <input 
                    type="email" 
                    placeholder="Enter your email" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ padding: '10px', fontSize: '16px' }}
                    required
                />
                <button 
                    type="submit" 
                    style={{ padding: '10px 20px', fontSize: '16px', marginLeft: '10px', cursor: 'pointer' }}
                >
                    Try it
                </button>
            </form>
            {loading && (
                <div style={{ marginTop: '20px' }}>
                    <p>Sending...</p>
                </div>
            )}
            {message && !loading && (
                <div 
                    style={{ 
                        marginTop: '20px', 
                        padding: '10px', 
                        border: messageType === 'success' ? '1px solid green' : '1px solid red', 
                        color: messageType === 'success' ? 'green' : 'red' 
                    }}
                >
                    {message}
                </div>
            )}
        </div>
    );
};

const Demo = () => (
    <div>
        <h1>Welcome to the Quiz App --</h1>
        <p><Link to="/quiz">Start the Quiz</Link></p>
    </div>
);

export default App;
